import _mapValues from 'lodash/mapValues';

import { AfterPurchaseStep, AfterPurchaseRoutes, QUIZ_NAMES, SUBSCRIPTION_NAMES } from './screens';

export const SUBSCRIPTION = {
  [SUBSCRIPTION_NAMES.MAIN]: '/subscription/main',
  [SUBSCRIPTION_NAMES.SUCCESS]: '/subscription/success',
  [SUBSCRIPTION_NAMES.APP_BUNDLE_GIFT]: '/subscription/app-bundle-gift',
  [SUBSCRIPTION_NAMES.UTISELF_OFFER]: '/subscription/utiself-offer',
  [SUBSCRIPTION_NAMES.TOOLBOXES_OFFER]: '/subscription/toolboxes-offer',
  [SUBSCRIPTION_NAMES.ADHD_OFFER]: '/subscription/adhd-offer',
};

export const QUIZ = _mapValues(QUIZ_NAMES, (v) => '/' + v.toLowerCase() + '/*');

export const ROUTES = {
  ...QUIZ,
  ...SUBSCRIPTION
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  SUBSCRIPTION_NAMES.MAIN,
  SUBSCRIPTION_NAMES.SUCCESS,
  SUBSCRIPTION_NAMES.APP_BUNDLE_GIFT,
  QUIZ_NAMES.QUIZ_PULSE_MEASURING_MAGIC,
  QUIZ_NAMES.QUIZ_EMAIL,
];

const AFTER_PURCHASE: { [key in AfterPurchaseStep]: void } = {
  [SUBSCRIPTION_NAMES.APP_BUNDLE_GIFT]: undefined,
  [SUBSCRIPTION_NAMES.UTISELF_OFFER]: undefined,
  [SUBSCRIPTION_NAMES.TOOLBOXES_OFFER]: undefined,
  [SUBSCRIPTION_NAMES.ADHD_OFFER]: undefined,
}

export const AFTER_PURCHASE_ROUTES = Object.keys(AFTER_PURCHASE) as AfterPurchaseRoutes;

