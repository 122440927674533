import _merge from 'lodash/merge';

import Localization from '@web-solutions/module-localization';
import { initLocalization as initFR } from '@web-solutions/face-reading';
import { initLocalization as initMS } from '@web-solutions/manage-subscription';
import HABIO from '@web-solutions/habio/src/localization/locales/en.json';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

const resources = {
  en: {
    translation: _merge(HABIO, require('./locales/en.json')),
  },
};

const resourcesMS = {
  en: require('./manage-sub/en.json'),
};

export function initLocalization({ stress2anxiety }: { stress2anxiety?: boolean } = {}) {
  Localization.init(resources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL }, { postProcess: stress2anxiety ? ['stress2anxiety'] : undefined });
  initFR();
  initMS(resourcesMS);
};

