import { Currency } from '@web-solutions/react-billing/constants';

import {ProgressDescriptionType, type FaceCapture} from '@web-solutions/face-reading/types';

import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type LString,
  type RemoteConfig as CoreRemoteConfig,
  type Nullable,
  BaseOfferProductTypes,
} from 'core/constants/remote-config';

import { AfterPurchaseRoutes, FlowStep } from './screens';

export enum SummarySection {
  HEADER = 'header',
  HEADER_STRESS = 'header_stress',
  FEEDBACK_LIST = 'feedback_list',
  MONEY_BACK = 'money_back',
  MONEY_BACK_POLICY = 'money_back_policy',
  FACE_CHECK = 'face_check',
  FACE_OVERVIEW = 'face_overview',
  LAST_WEEK = 'last_week',
  SPHERES_GRAPH = 'spheres_graph',
  DATA_ANALYSIS = 'data_analysis',
  DOWNLOADED = 'download',
  HOW_IT_WORKS = 'how_it_works',
  SMART_WAY = 'smart_way',
  FAQ = 'faq',
  CAPSULE_TOUR = 'capsule_tour',
  HANDLING_STRESS = 'handling_stress',
  WILL_HELP_YOU = 'will_help_you',
  GOALS = 'goals',
  WE_ANALYZED = 'we_analyzed',

  TEXT_HEADER = 'text_header',
  UNLOCK_PRODUCTIVITY = 'unlock_productivity',
  PROCRASTINATION_SUMMARY = 'procrastination_summary',
  APP_BUNDLE_GIFT = 'app_bundle_gift',
}

export enum QUIZ_WELCOME_CONTENT {
  MENTAL = 'mental',
  TARGET = 'target',
  GIRL = 'girl',
  MAN = 'man',
}

export enum SuccessPageType {
  DEFAULT = 'default',
  EMAIL = 'email',
  EXTENDED_EMAIL = 'extended_email',
}

export enum OfferProductId {
  MENTAL = 'mental',
  SELF = 'self',
  ALL = 'all',
}

export type ReportViewType = 'WITH_IMAGE' | 'NO_IMAGE'

export interface OfferProductTypes extends Omit<BaseOfferProductTypes<OfferProductId>, 'subTitle'> {
  materialsAmount: number,
}

export type FinallOfferProduct = Omit<OfferProductTypes, | 'id'>;

export type AfterPurchaseFlow = AfterPurchaseRoutes;

export interface RemoteConfig extends CoreRemoteConfig<FlowStep> {
  landingStack: Array<SummarySection | 'plans' | 'reserved_price'>,
  landingTopButtonAfterBlockType: SummarySection[],

  summaryHeader: {
    title: LString,
    subtitle: LString,
  },

  magicDurationLoad: number,
  utiselfOffer: {
    button_text: string,
    type: "short" | "long",
    activePrice: string,
    price: number,
    currency: Currency,
  },
  afterPurchaseFlow: AfterPurchaseFlow,
  autocompleteEmail: boolean,
  focusEmail: boolean,

  isFaceReadingSkipAvailable: boolean,
  isFaceReadingUploadPhotoAvailable: boolean,
  isFaceReadingBackAvailable: boolean,
  isFaceReadingApproveAvailable: boolean,

  pulseMeasurementDuration: number,
  isBlurOnHandlingStress: boolean,

  cameraInitTimeout: number,

  quizWelcomePage: {
    uptitle: LString,
    title: LString,
    subtitle: LString,
    button: LString,
    content: QUIZ_WELCOME_CONTENT,
  },
  emailPageTitle: LString,

  successPageType: SuccessPageType,

  stress2anxiety: boolean,
  appBundleGiftSectionTitle: string,
  isOneClickFootnote: boolean,
  appBundleGift: {
    title: LString,
    text: LString,
    button: LString,
    isSkipLink: boolean,
    isEnabledCongratulationsPopup: boolean,
  },
  toolboxOffer: {
    title: string,
    sub_title: string,
    button: string,
    skip_button: string,
    activeOfferId: OfferProductId | null,
    successModalEnabled: boolean,
    products: OfferProductTypes[],
    compact: boolean,
  },
  toolboxFinalOffer: {
    enabled: boolean,
    button: string,
    skip_button: string,
    product: FinallOfferProduct,
  },
  adhdOffer: {
    title: LString,
    text: LString,
    button: LString,
    product: Omit<OfferProductTypes, 'id' | 'materialsAmount'>,
    view: ReportViewType,
  },
  faceCapture: FaceCapture,
  progressDescriptionType: ProgressDescriptionType,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,

  landingStack: [],
  landingTopButtonAfterBlockType: [],
  isDownloadPdfInBrowser: false,
  summaryHeader: {
    title: '',
    subtitle: '',
  },

  flow: [],

  consentCookieModal: {
    enabled: false,
    type: 'long',
    placement: 'QUIZ_WELCOME',
  },

  magicDurationLoad: 8000,

  utiselfOffer: {
    button_text: '',
    type: "long",
    activePrice: "",
    price: 47,
    currency: 'USD',
  },
  afterPurchaseFlow: ['APP_BUNDLE_GIFT', 'UTISELF_OFFER', 'TOOLBOXES_OFFER'],
  autocompleteEmail: false,
  focusEmail: false,

  isFaceReadingSkipAvailable: true,
  isFaceReadingUploadPhotoAvailable: false,
  isFaceReadingBackAvailable: false,
  isFaceReadingApproveAvailable: false,

  pulseMeasurementDuration: 20,
  isBlurOnHandlingStress: false,

  cameraInitTimeout: 4000,

  quizWelcomePage: {
    uptitle: '',
    title: '',
    subtitle: '',
    button: '',
    content: QUIZ_WELCOME_CONTENT.MENTAL,
  },
  emailPageTitle: { en: '' },

  successPageType: SuccessPageType.DEFAULT,

  stress2anxiety: false,

  appBundleGiftSectionTitle: '',
  isOneClickFootnote: false,
  appBundleGift: {
    title: '',
    text: '',
    button: '',
    isSkipLink: false,
    isEnabledCongratulationsPopup: false,
  },
  toolboxOffer: {
    title: '',
    sub_title: '',
    button: '',
    skip_button: '',
    activeOfferId: OfferProductId.ALL,
    successModalEnabled: true,
    products: [],
    compact: false,
  },
  toolboxFinalOffer: {
    enabled: true,
    button: '',
    skip_button: '',
    product: {
      title: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      currency: 'USD',
      price: 1,
      materialsAmount: 50,
    },
  },
  adhdOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    view: "WITH_IMAGE",
  },
  faceCapture: {
    stressScanEnabled: true,
    isAutoTakeAfterScan: true,
    withHeartRateLine: true,
    duration: 15000,
    mode: 'basic',
  },
  progressDescriptionType: ProgressDescriptionType.DEFAULT,
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
