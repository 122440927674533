import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from 'core/utils/normalize-blocks'

import { QUIZ_NAMES, type FlowStep } from 'src/constants/screens';
import { type RemoteConfig, } from 'src/constants/remote-config';

const ROUTES = Object.values(QUIZ_NAMES);

const getFlow = (state: any) => (state.remoteConfig as RemoteConfig).flow;

export const selectFlowBlocks = createSelector([getFlow], (flow) => {
  let normalizedFlow = normalizeBlocks(flow);
  normalizedFlow = normalizedFlow.map(i => _intersection(i, ROUTES)).filter(i => !!i.length);
  return normalizedFlow;
});

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow as FlowStep[];
});
