import React from 'react';
import { RecurlyProvider, ThreeDSecureAction, ThreeDSecureActionProps } from '@recurly/react-recurly';

import { getPublicKeys } from '@web-solutions/react-billing';

import { Modal } from 'core/ui-elements';

import classes from './style.module.scss';

const ThreeDSecure: React.FC<ThreeDSecureActionProps> = (props) => {
  const publicKeys = getPublicKeys();

  return (
    <Modal isOpen className={classes.modal}>
      {/** @ts-ignore*/}
      <RecurlyProvider publicKey={publicKeys.recurly?.public_key}>
        <ThreeDSecureAction
          {...props}
        />
      </RecurlyProvider>
    </Modal>
  );
};

export default React.memo(ThreeDSecure);
