import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { tm, T } from '@web-solutions/module-localization';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Title, Section } from '@web-solutions/core/ui-elements';

import TrialReminder from '@web-solutions/core/payment/components/trial-reminder';
import PaymentDescription from '@web-solutions/core/payment/components/payment-description';
import { ProductDetails, selectSpecialOfferEndDate } from '@web-solutions/core/store/billing/selectors';

import { FixedButton, type FixedButtonProps } from '../fixed-button';
import InjectedPaymentMethodSwitch from '../injected-payment-method/components/switch';

import { PaymentSafe } from '../../containers/payment-safe';

import PolicyCheckbox from './policy-check-box';
import { Item } from './item';

import classes from './style.module.scss';

interface PlansProps {
  activeProduct: ProductDetails,
  products: ProductDetails[],
  showTerms?: boolean,
  onProductClick: (p: ProductDetails) => void,
  onFixedButtonClick?: FixedButtonProps['onClick'],
  onPayPalSubmit?: FixedButtonProps['onPayPalSubmit'],
  subtitle?: React.ReactNode,
}

export const Plans: React.FC<PlansProps> = ({
  activeProduct,
  products,
  showTerms = true,
  onProductClick,
  onFixedButtonClick,
  onPayPalSubmit,
  subtitle,
}) => {
  const plansSectionId = 'plans';
  const productsListId = 'products-list';

  const {
    policyLinks,
    policyCheckboxChecked,
    buttonsConfigs,
    productsTitle,
    showTermsAtPlans,
    showPaymentSafeAtPlans,
    isInjectedPaymentMethodOnPage,
    isTrialPeriodReminder,
    specialOffer,
  } = useRemoteConfig();

  const specialOfferEndDate = useSelector(selectSpecialOfferEndDate);

  const handleItemClick = useCallback(
    (selectedProduct: ProductDetails) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      onProductClick(selectedProduct);
    },
    [onProductClick],
  );

  const renderItems = useCallback(
    () => products.map((product) => (
      <Item
        key={product.id}
        isActive={activeProduct.id === product.id}
        onClick={handleItemClick}
        product={product}
      />
    )),
    [products, activeProduct.id, handleItemClick],
  );

  const title = specialOfferEndDate
    ? <T k={'core.special_offer.products'} tOptions={{ discount: specialOffer.discount }} />
    : tm(productsTitle, '');

  const classContainer = classNames(classes.container);

  return (
    <Section className={classNames(classes.wrap)} id={plansSectionId}>
      {!!title && <Title level="h2">{title}</Title>}
      {subtitle}
      <div className={classContainer}>
        <ul className={classes.productsList} id={productsListId}>{renderItems()}</ul>
      </div>

      {
        isInjectedPaymentMethodOnPage && (
          <InjectedPaymentMethodSwitch />
        )
      }

      {
        (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
      }

      {!!onFixedButtonClick && !!onPayPalSubmit &&
        <FixedButton
          activeProduct={activeProduct}
          buttonsConfigs={buttonsConfigs}
          onClick={onFixedButtonClick}
          id={productsListId}
          onPayPalSubmit={onPayPalSubmit}
        />
      }

      {
        showTerms
          ? showTermsAtPlans
            ? (
              <PaymentDescription
                activeProduct={activeProduct}
                isShort
              />
            )
            : (
              <PolicyCheckbox policyLinks={policyLinks} checkboxChecked={policyCheckboxChecked} />
            )
          : null
      }

      {showPaymentSafeAtPlans ? <PaymentSafe isModal={false} /> : null}

    </Section>
  );
};
