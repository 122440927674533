import { QUIZ_NAMES as HABIO, type HabioFlowStep } from '@web-solutions/habio/src/constants/screens';

export const SUBSCRIPTION_NAMES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  APP_BUNDLE_GIFT: 'APP_BUNDLE_GIFT',
  UTISELF_OFFER: 'UTISELF_OFFER',
  TOOLBOXES_OFFER: 'TOOLBOXES_OFFER',
  ADHD_OFFER: 'ADHD_OFFER'
} as const;

type SubscriptionNamesKeys = keyof typeof SUBSCRIPTION_NAMES;
type AfterPurchase = Exclude<typeof SUBSCRIPTION_NAMES[SubscriptionNamesKeys], "MAIN" | "SUCCESS">
export type AfterPurchaseRoutes = Array<AfterPurchase>;
export type AfterPurchaseStep = AfterPurchase;

const REFLECTIO = {
  QUIZ_WELCOME_SPIN: 'QUIZ_WELCOME_SPIN',
  QUIZ_WELCOME_SUBTITLE: 'QUIZ_WELCOME_SUBTITLE',
  QUIZ_WELCOME_MENTAL_STATE: 'QUIZ_WELCOME_MENTAL_STATE',
  QUIZ_WELCOME: 'QUIZ_WELCOME',
  QUIZ_GENDER: 'QUIZ_GENDER',
  QUIZ_SELECTING_GENDER: 'QUIZ_SELECTING_GENDER',
  QUIZ_AGE: 'QUIZ_AGE',
  QUIZ_STRESSED_LIFE_AREAS: 'QUIZ_STRESSED_LIFE_AREAS',
  QUIZ_STRESSED_OUT_CONSTANTLY: 'QUIZ_STRESSED_OUT_CONSTANTLY',
  QUIZ_MENTAL_WELL_BEING: 'QUIZ_MENTAL_WELL_BEING',
  QUIZ_STRESS_AFFECT: 'QUIZ_STRESS_AFFECT',
  QUIZ_LIFE_WITHOUT_STRESS: 'QUIZ_LIFE_WITHOUT_STRESS',
  QUIZ_MONTH_GOALS: 'QUIZ_MONTH_GOALS',
  QUIZ_EASILY_UPSET: 'QUIZ_EASILY_UPSET',
  QUIZ_MANY_THINGS: 'QUIZ_MANY_THINGS',
  QUIZ_WIND_DOWN: 'QUIZ_WIND_DOWN',
  QUIZ_GET_ANGRY: 'QUIZ_GET_ANGRY',
  QUIZ_HARD_TIME: 'QUIZ_HARD_TIME',
  QUIZ_STRESSFUL_SITUATIONS: 'QUIZ_STRESSFUL_SITUATIONS',
  QUIZ_MY_PRODUCTIVITY: 'QUIZ_MY_PRODUCTIVITY',
  QUIZ_MY_RELATIONSHIP: 'QUIZ_MY_RELATIONSHIP',
  QUIZ_QUALITY_LIFE: 'QUIZ_QUALITY_LIFE',
  QUIZ_STRESS_CONTROL: 'QUIZ_STRESS_CONTROL',
  QUIZ_WORK: 'QUIZ_WORK',
  QUIZ_SLEEP: 'QUIZ_SLEEP',
  QUIZ_STRESS: 'QUIZ_STRESS',
  QUIZ_TRANSITION_ON_RUN: 'QUIZ_TRANSITION_ON_RUN',
  QUIZ_TRANSITION_CYCLE_FORMS: 'QUIZ_TRANSITION_CYCLE_FORMS',
  QUIZ_TRANSITION_CONTROL: 'QUIZ_TRANSITION_CONTROL',
  QUIZ_TRANSITION_GET_MEASURED: 'QUIZ_TRANSITION_GET_MEASURED',
  QUIZ_TRANSITION_OVERWHELMING_LIFE: 'QUIZ_TRANSITION_OVERWHELMING_LIFE',
  QUIZ_TRANSITION_EMOTIONAL_DISTRESS: 'QUIZ_TRANSITION_EMOTIONAL_DISTRESS',
  QUIZ_TRANSITION_HEALTH_ISSUES: 'QUIZ_TRANSITION_HEALTH_ISSUES',
  QUIZ_TRANSITION_STAY_SYNC: 'QUIZ_TRANSITION_STAY_SYNC',
  QUIZ_TRANSITION_INFORMED_DECISIONS: 'QUIZ_TRANSITION_INFORMED_DECISIONS',
  QUIZ_TRANSITION_GETS_IMPROVED: 'QUIZ_TRANSITION_GETS_IMPROVED',
  QUIZ_PREGNANT_OR_BREASTFEEDING: 'QUIZ_PREGNANT_OR_BREASTFEEDING',
  QUIZ_DIABETES: 'QUIZ_DIABETES',
  QUIZ_FACE_READING: 'QUIZ_FACE_READING',
  QUIZ_PULSE_MEASURING: 'QUIZ_PULSE_MEASURING',
  QUIZ_PULSE_MEASURING_MAGIC: 'QUIZ_PULSE_MEASURING_MAGIC',
  QUIZ_PAST_MONTH_STATE: 'QUIZ_PAST_MONTH_STATE',
  QUIZ_LIFES_DEMANDS: 'QUIZ_LIFES_DEMANDS',
  QUIZ_DIAGNOSED: 'QUIZ_DIAGNOSED',
  QUIZ_FEEL_EXHAUSTED: 'QUIZ_FEEL_EXHAUSTED',
  QUIZ_MOOD_SWINGS: 'QUIZ_MOOD_SWINGS',
  QUIZ_HEART_PALPITATION: 'QUIZ_HEART_PALPITATION',
  QUIZ_WELL_BEING_UNDER_CONTROL: 'QUIZ_WELL_BEING_UNDER_CONTROL',
  QUIZ_WOULD_LIKE: 'QUIZ_WOULD_LIKE',
  QUIZ_DIFFICULT_TO_MANAGE: 'QUIZ_DIFFICULT_TO_MANAGE',
  QUIZ_WORRY_ABOUT: 'QUIZ_WORRY_ABOUT',
  QUIZ_ANXIOUS_ABOUT_TASKS: 'QUIZ_ANXIOUS_ABOUT_TASKS',
  QUIZ_EASILY_OVERWHELMED: 'QUIZ_EASILY_OVERWHELMED',
  QUIZ_EMOTIONS_ON_ROLLERCOASTER: 'QUIZ_EMOTIONS_ON_ROLLERCOASTER',
  QUIZ_TEND_TO_FURIOUS: 'QUIZ_TEND_TO_FURIOUS',
  QUIZ_STRUGGLE_TO_CONTROL: 'QUIZ_STRUGGLE_TO_CONTROL',
  QUIZ_FEEL_ISOLATED: 'QUIZ_FEEL_ISOLATED',
  QUIZ_DIFFICULTY_CONCENTRATING: 'QUIZ_DIFFICULTY_CONCENTRATING',
  QUIZ_WORK_LONG_DAYS: 'QUIZ_WORK_LONG_DAYS',
  QUIZ_TRACKING_STRESS: 'QUIZ_TRACKING_STRESS',
  QUIZ_EMAIL: 'QUIZ_EMAIL',
  QUIZ_MAGIC: 'QUIZ_MAGIC',
} as const;

export const QUIZ_NAMES = {
  ...HABIO,
  ...REFLECTIO,
}

type ReflectioFlowStep = typeof REFLECTIO[keyof typeof REFLECTIO];

export type FlowStep = HabioFlowStep | ReflectioFlowStep;
