import { createAction } from 'redux-actions';

import Attribution from '@web-solutions/module-attribution';

import Analytics from '@web-solutions/module-analytics';

import { init as initRemoteConfig } from 'core/store/remote-config/actions';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { initLocalization } from 'src/localization';

import { init as initRouting } from '../routing/actions';
import { auth } from '../profile/actions';

import { getMagnusToken, validateRemoteConfig } from './utils';
import { SET_LOADED, SET_PENDING, SET_APP_LINK } from './types';

export const setLoaded = createAction(SET_LOADED);
export const setPending = createAction(SET_PENDING);
const setAppLink = createAction(SET_APP_LINK);

export const load = () => async (dispatch) => {
  try {
    dispatch(auth());
    const { stress2anxiety } = await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG, { validate: validateRemoteConfig }));
    initLocalization({ stress2anxiety, })
    await dispatch(initRouting());
    await dispatch(initAppLink());
  } catch (error) {
    console.warn('[ERROR INIT APP]', error); 
  } finally {
    dispatch(setLoaded(true));
  }
};

export const initAppLink = () => async (dispatch) => {
  const appLink = await Attribution.getLinkToApp();
  dispatch(setAppLink(appLink));
};

export const downloadApp = () => async (dispatch, getState) => {
  const state = getState();
  const { app: { appLink } } = state;
  Analytics.trackEvent('install_app', 'click');
  window.location.href = appLink;
};
