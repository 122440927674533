const normalizeBlocks = (input: Array<string | string[]>): string[][] => {
  input = input || [];
  const output = [];

  for (let i = 0; i < input.length; i++) {
    if (Array.isArray(input[i])) {
      output.push(input[i]);
    } else {
      const candidate = [input[i]];
      let j = i + 1;

      while (j < input.length && !Array.isArray(input[j])) {
        candidate.push(input[j++]);
      }
      i = j - 1;
        output.push(candidate);
      }
  }
  return output as string[][];
};

export default normalizeBlocks;
