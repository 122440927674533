import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { Title, Button, Text } from '@web-solutions/core/ui-elements';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { goAppLink, selectSubscription, useSliceDispatch } from '../../slice';

import { t, tm, TM, T } from '../../../localization';

import { ReactComponent as IconClose } from '../../components/icons/close-icon.svg';

import { useManageContext } from '../../hooks/use-manage-context';

import classes from './style.module.scss';

const tKey = 'manage.email_cancel';

const category = 'manage_email_cancel';

export const EmailCancelPage: React.FC = () => {
  const { mngSubEmailCancelPage } = useRemoteConfig();
  const { emailCancelContent } = useManageContext();
  const dispatch = useSliceDispatch();
  const sub = useSelector(selectSubscription);

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN);
  }, []);

  const handleCloseClick = () => {
    Analytics.flush();
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    dispatch(goAppLink());
  };

  return (
    <div className={classes.wrap}>
      <div>
        <Title level="h1" className={classes.title}>
          {tm(mngSubEmailCancelPage?.title, `${tKey}.title`)}
        </Title>
        <Text className={classes.text}>
          <TM
            k={mngSubEmailCancelPage?.subtitle}
            defaultKey={`${tKey}.subtitle`}
            tOptions={{ email: sub?.email, supportEmail: process.env.REACT_APP_SUPPORT_EMAIL }}
            components={{ span: <span className={classes.dark} /> }}
          />
        </Text>
      </div>
      <button type="button" onClick={handleCloseClick} className={classes.close}>
        <IconClose width="16px" height="16px" />
      </button>
      {emailCancelContent}
      <Text className={classNames(classes.text, classes.dark)}>{t(`${tKey}.text`)}</Text>

      <Button
        onClick={handleCloseClick}
        type="button"
        className={classes.bottom_button}
        title={t(`${tKey}.button_title`)}
      />
      <Text className={classes.footnote}>
        <T
          k={`${tKey}.footnote`}
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          components={{ email: <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className={classes.email} /> }}
        />
      </Text>
    </div>
  );
};
