import React, { useCallback, useState, useEffect, } from 'react';
import { useNavigate, useResolvedPath, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import {ProgressDescriptionType, FaceCapture} from '@web-solutions/face-reading/types';

import { t } from './localization';
import { setFaceData } from './slice';
import { type ImgData, ROUTES, type LoadMethod } from './constants';

import { FaceReadingWelcome } from './welcome';
import { FaceReadingCapture } from './capture';
import { FaceReadingAnalyzing } from './analyzing';
import { FaceReadingUpload } from './upload';
import { FaceReadingApprove } from './approve';
import ErrorPopup from './error-popup';

import * as detector from './back-detector';

import classes from './style.module.scss';

interface FaceReadingProps {
  analyticsCategory: string;
  isSkipAvailable: boolean;
  isUploadAvailable: boolean;
  isApproveAvailable: boolean;
  isBackAvailable: boolean;
  cameraInitTimeout: number;
  analyzingDuration: number,
  onDone: () => void;
  onSkip: () => void;
  captureConfig?: FaceCapture;
  progressDescriptionType?: ProgressDescriptionType,
}

export const FaceReading: React.FC<FaceReadingProps> = ({
  analyticsCategory,
  isSkipAvailable,
  isUploadAvailable,
  isApproveAvailable,
  isBackAvailable,
  cameraInitTimeout,
  analyzingDuration,
  onDone,
  onSkip,
  captureConfig,
  progressDescriptionType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useResolvedPath("");

  const [error, setError] = useState('');
  const [animation, setAnimation] = useState(false);

  const welcomeEventCategory = analyticsCategory + '_WELCOME';
  const uploadEventCategory = analyticsCategory + '_UPLOAD';
  const captureEventCategory = analyticsCategory + '_CAPTURE';
  const analyzingEventCategory = analyticsCategory + '_ANALYZING';
  const approveEventCategory = analyticsCategory + '_APPROVE';

  useEffect(() => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.OPEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWelcomeCaptureClick = () => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.SUCCESS);
    navigate(match.pathname + ROUTES.CAPTURE, { replace: true });
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.OPEN);
  };

  const handleWelcomeUploadClick = () => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.SUCCESS);
    navigate(match.pathname + ROUTES.UPLOAD, { replace: true });
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.OPEN);
  };

  const handleWelcomeSkipClick = () => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.SKIP);
    onDone();
  };

  const handleApprovePhoto = (img: ImgData, type: LoadMethod) => {
    navigate(match.pathname + ROUTES.APPROVE, { state: { img: img?.img, width: img?.width, height: img?.height, type, isFullScreen: img?.isFullScreen }, replace: true });
  }

  const handleDetectorSuccess = useCallback(({ img, width, height, isFullScreen }: ImgData) => {
    dispatch(setFaceData({ img, width, height, isFullScreen }));
    navigate(match.pathname + ROUTES.ANALYZING, { replace: true });
    setAnimation(true);
    Analytics.trackEvent(analyzingEventCategory, EVENT_ACTION.OPEN);

    return detector.process(img)
      .catch(() => null)
      .then((keypoints) => {
        if (!keypoints) {
          setAnimation(false);
          dispatch(setFaceData({ faceImg: null }));
          throw Error('not_face');
        } else {
          dispatch(setFaceData({ img, keypoints, width, height, isFullScreen }));
        }
      });
  }, [analyzingEventCategory, dispatch, navigate, match.pathname]);

  const handleCaptureSuccess = useCallback((img: ImgData) => {
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.SUCCESS);
    handleDetectorSuccess(img).catch((err) => {
      Analytics.trackEvent(captureEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
      setError(t('error_no_face'));
      navigate(match.pathname + ROUTES.CAPTURE, { replace: true });
    });
  }, [captureEventCategory, handleDetectorSuccess, navigate, match.pathname]);

  const handleUploadSuccess = useCallback((img: ImgData) => {
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.SUCCESS);
    handleDetectorSuccess(img).catch((err) => {
      Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
      navigate(match.pathname + ROUTES.UPLOAD, { replace: true, state: { src: img?.img } });
    });
  }, [handleDetectorSuccess, navigate, match.pathname, uploadEventCategory]);

  const handleCaptureError = useCallback((err: Error) => {
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
    onDone();
  }, [captureEventCategory, onDone]);

  const handleAnalyzingDone = useCallback(() => {
    setAnimation(false);
    setTimeout(onDone, 2000);
  }, [setAnimation, onDone]);

  const handleErrorPopupClose = useCallback(() => {
    setError('');
  }, [setError]);

  const handleCaptureSkipClick = useCallback(() => {
    setError('');
    Analytics.trackEvent(captureEventCategory, 'try_later');
    onSkip();
  }, [captureEventCategory, onSkip]);

  const handleUploadCloseClick = useCallback(() => {
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.CLOSE);
    navigate(match.pathname, { replace: true });
  }, [navigate, match.pathname, uploadEventCategory]);

  const handleUploadSkipClick = useCallback(() => {
    Analytics.trackEvent(uploadEventCategory, 'try_later');
    onSkip();
  }, [onSkip, uploadEventCategory]);

  return (
    <div className={classes.container}>
      <Routes>
        <Route path={ROUTES.UPLOAD} element={
          <FaceReadingUpload
            onSuccess={isApproveAvailable ? handleApprovePhoto : handleUploadSuccess}
            onCloseClick={handleUploadCloseClick}
            onSkipClick={handleUploadSkipClick}
          />
        } />
        <Route path={ROUTES.CAPTURE} element={
          <FaceReadingCapture
            cameraInitTimeout={cameraInitTimeout}
            onError={handleCaptureError}
            onSuccess={isApproveAvailable ? handleApprovePhoto : handleCaptureSuccess}
            eventCategory={captureEventCategory}
            isBackAvailable={isBackAvailable}
            captureConfig={captureConfig}
          />
        } />
        <Route path={ROUTES.ANALYZING} element={
          <FaceReadingAnalyzing
            analyzingDuration={analyzingDuration}
            onDone={handleAnalyzingDone}
            progressDescriptionType={progressDescriptionType}
          />
        } />
        <Route path={ROUTES.APPROVE} element={
          <FaceReadingApprove
            eventCategory={approveEventCategory}
            onCaptureSuccess={handleCaptureSuccess}
            onUploadSuccess={handleUploadSuccess}
            captureEventCategory={captureEventCategory}
            uploadEventCategory={uploadEventCategory}
          />
        } />
        <Route path={'*'} element={
          <FaceReadingWelcome
            isSkipAvailable={isSkipAvailable}
            isUploadAvailable={isUploadAvailable}
            onSkipClick={handleWelcomeSkipClick}
            onCaptureClick={handleWelcomeCaptureClick}
            onUploadClick={handleWelcomeUploadClick}
          />
        } />
      </Routes>
      {animation && <div className={classes.animation}></div>}
      <ErrorPopup
        visible={!!error}
        message={error}
        onClose={handleErrorPopupClose}
        onSkipClick={handleCaptureSkipClick}
      />
    </div>
  );
};
