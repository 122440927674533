import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { Block, Text, } from '@web-solutions/core/ui-elements';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { t, tm, } from '../../../../localization';
import { SubscriptionStatus } from '../../../types';

import { useManageContext } from '../../../hooks/use-manage-context';
import { selectProduct, selectSubscription } from '../../../slice';

import classes from './style.module.scss';

const tKey = 'manage.current_plan';

export const CurrentPlan: React.FC = () => {

  const subscription = useSelector(selectSubscription);
  const product = useSelector(selectProduct);
  const { currentPlanContent } = useManageContext();

  const { mngSubMainPage: config } = useRemoteConfig();

  if (!subscription || !product) {
    return null;
  }

  return (
    <Block className={classes.wrap}>
      <h2>{t(`${tKey}.title`)}</h2>
      <div className={classes.row}>
        <Text className={classes.left}>{t(`${tKey}.status`)}</Text>
        <Text
          className={classNames(classes.right, {
            [classes.red]: subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED
          })}>
          {t(`manage.status.${subscription.status}`)}
        </Text>
      </div>
      {product.period && (
        <div className={classes.row}>
          <Text className={classes.left}>{t(`${tKey}.period`)}</Text>
          <Text className={classes.right}>{t(`manage.period.${product.period}`)}</Text>
        </div>
      )}
      <div className={classes.row}>
        <Text className={classes.left}>{t(`${tKey}.price`)}</Text>
        <Text className={classes.right}>{tm(config.currentPlanPrice, '', { ...product })}</Text>
      </div>
      {subscription.status === SubscriptionStatus.CANCELED
        ? (
          <div className={classes.row}>
            <Text className={classes.left}>{t(`${tKey}.expire`)}</Text>
            <Text className={classes.right}>{dayjs(subscription.expire_at).format('LL')}</Text>
          </div>
        ) : subscription.status === SubscriptionStatus.EXPIRED
          ? (
            <div className={classes.row}>
              <Text className={classes.left}>{t(`${tKey}.expired`)}</Text>
              <Text className={classes.right}>{dayjs(subscription.expire_at).format('LL')}</Text>
            </div>
          )
          : null}
      {currentPlanContent}
    </Block>
  );
};
