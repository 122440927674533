import { useSelector } from 'react-redux';

import useCoreTimer from 'core/hooks/use-timer';

const stringForTimer = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const useTimer = () => {
  const discountEndDate = useSelector((state) => state.billing.discountEndDate);
  const specialOfferEndDate = useSelector((state) => state.billing.specialOfferEndDate)
  const time = specialOfferEndDate || discountEndDate;
  const { minutes, seconds } = useCoreTimer(time);
  const shown = minutes >= 0 && seconds >= 0;

  return {
    shown,
    minutes: stringForTimer(minutes),
    seconds: stringForTimer(seconds),
    text: `${stringForTimer(minutes)}:${stringForTimer(seconds)}`,
  };
};
