import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss';

interface Props {
  type: 'badge' | 'text';
}

const tKey = 'core.trials_left';

const TrialsLeft: React.FC<Props> = ({ type }) => {
  const maxTrialLeftCount = 5;
  const minTrialLeftCount = 3;
  const [counter, setCounter] = useState(maxTrialLeftCount);
  const { trialsLeft } = useSelector(remoteConfigSelector);

  useEffect(() => {
    let counterTimeoutId = setTimeout(() => {
      if (counter > minTrialLeftCount) {
        setCounter(counter - 1);
      }
    }, trialsLeft.time);

    return(() => {
      clearTimeout(counterTimeoutId);
    })
  },[counter])

  return (
    <>
      {
        trialsLeft.enabled && (
          <>
            {
              type === 'badge' ? (
                <div className={classes.badge}>{t(`${tKey}.badge`, { counter })}</div>
              ) : (
                <div className={classes.text}>{t(`${tKey}.text`, { counter })}</div>
              )
            }
          </>
        )
      }
    </>
  )
}

export default TrialsLeft;
