import { parseUrlParams } from 'core/utils/url-sync';

import { render } from './render';

parseUrlParams();

let pType = window.location.pathname.split('/')[1];

let promiseApp;

switch (pType) {
  case 'terminate':
    promiseApp = import('@web-solutions/manage-subscription').then(r => r.Terminate);
    break;
  case 'manage':
    promiseApp = import('./screens/manage').then(r => r.default);
    break;
  case 'quiz-book':
    promiseApp = import('./screens/quiz/book').then(r => r.default);
    break;
  case 'summary-book':
    promiseApp = import('./screens/subscription/book').then(r => r.default);
    break;
  default:
    promiseApp = import('./screens/quiz').then(r => r.default);
    break;
}

promiseApp.then(render);
