import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { t, tm } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { getSubTitleText, getProductTitle } from 'core/utils/products';
import { PriceAfterTrialType } from 'core/payment/constants';
import { ProductDetails } from 'core/store/billing/selectors';

import { DiscountWithTimer } from '../discount-with-timer';
import { SmallBadge } from '../small-badge';
import { SoldLine } from '../sold-line';

import classes from './style.module.scss';

const tKey = 'core.plans';

interface ItemProps {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
};

export const Item: React.FC<ItemProps> = ({
  isActive,
  product,
  onClick,
}) => {
  const {
    subTitle,
    weekPrice,
    priceText,
    period,
    isTrial,
    trialPeriodDays,
    trialPeriodPrice,
    isIntroductory,
    perWeekTitle,
    perWeekText,
    textLineThrough,
    previousPerWeekTitle,
    sold,
  } = product;

  const { priceAfterTrialType } = useSelector(remoteConfigSelector);
  const priceForSubTitle = isTrial ? trialPeriodPrice : priceText;
  const titleText = getProductTitle(product);
  const subTitleText = getSubTitleText(subTitle, period, isTrial, isIntroductory, trialPeriodDays, priceForSubTitle);
  const afterTrialText = tm(
    product.afterTrialText,
    `${tKey}.after_trial.${period}`,
    {
      ...product,
      price:
        priceAfterTrialType === PriceAfterTrialType.WEEK
          ? `${weekPrice}/${t(`core.payment_popup.price_texts.P1W`)}`
          : priceText,
      interpolation: { escapeValue: false, },
    });
  const perPeriodTKey = period.indexOf('D') === 2 ? 'per_day' : 'per_week';
  const perText = tm(perWeekText, `${tKey}.${perPeriodTKey}`);
  const perWeekPrice = perWeekTitle || weekPrice;

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const isSoldOut = !!sold?.total && !sold.left;

  const isLightColorPerWeek = !!previousPerWeekTitle && !isActive;

  return (
    <div className={classNames(classes.wrap, { [classes.disabled]: isSoldOut })}>
      <DiscountWithTimer
        discount={product.discount}
        badgeTitle={tm(product.badgeTitle, isSoldOut ? 'core.plans.sold_out' : '')}
        centered={product.badgeCentered}
        disabled={isSoldOut}
      />
      <button
        type="button"
        onClick={handleItemClick}
        className={classNames(classes.container, { [classes.isActiveContainer]: isActive })}
        disabled={isSoldOut}
      >
        <div className={classes.detailWrap}>
          <p className={classes.productTitle}>
            {titleText}
            <SmallBadge
              texts={product.smallBadges}
              disabled={isSoldOut}
            />
          </p>
          {!sold?.hiddenSubTitles ? (
            <>
              <p className={classes.productSubTitle}>
                {subTitleText}
                &nbsp;
                {textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '')}</span>}
              </p>
              {isTrial && (
                <p className={classes.afterTrialText}>
                  {afterTrialText}
                </p>
              )}
            </>
          ) : null}
          {sold?.total ? (
            <SoldLine
              isActive={isActive}
              left={sold.left}
              total={sold.total}
            />
          ) : null}
        </div>
        <div className={classes.perWeekWrap}>
          {previousPerWeekTitle && (
            <p className={classes.previousPerWeekTitle}>
              {tm(previousPerWeekTitle, '')}
            </p>
          )}
          <p className={classNames(classes.perWeekTitle, isLightColorPerWeek && classes.lightPerWeekTitle)}>
            {tm(perWeekPrice, '')}
          </p>
          <p className={classNames(classes.perWeekText, isLightColorPerWeek && !isActive && classes.lightPerWeekText)}>
            {perText}
          </p>
        </div>
      </button>
    </div>
  );
};
