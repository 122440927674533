import queryString from 'query-string';
import { createAction } from 'redux-actions';

import { setPurchase } from 'core/store/billing/actions';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { REDIRECT_TO_CHROME, doRedirectToChrome } from 'core/utils/redirect-to-chrome';

import { SUBSCRIPTION_NAMES } from 'src/constants/screens';
import { ROUTES, SUBSCRIPTION } from 'src/constants/routes';

import { processEmail, } from '../profile/actions';

import { selectFlow } from './selectors';

import {
  SET_COMPLETED,
  SET_START_ROUTE,
  SET_STEP,
} from './types';

export const setStep = createAction(SET_STEP);
const setStartRoute = createAction(SET_START_ROUTE);
export const setCompleted = createAction(SET_COMPLETED);

export const init = () => (dispatch, getState) => {
  const { redirectToChrome } = remoteConfigSelector(getState());
  const flow = selectFlow(getState());

  if (redirectToChrome === REDIRECT_TO_CHROME.ENABLED) {
    doRedirectToChrome(redirectToChrome);
  }

  const params = queryString.parse(window.location.search);

  if (params.completed !== undefined) {
    dispatch(setCompleted(true));
  }

  if (params.purchased !== undefined) {
    dispatch(setPurchase({}));
  }

  if (params.email) {
    dispatch(processEmail(params.email));
  }

  const {
    routing: { isCompleted },
  } = getState();

  const startRoute = !isCompleted ? ROUTES[flow[0].toUpperCase()] : dispatch(getRouteAfterFlow());
  dispatch(setStartRoute(startRoute));

  return Promise.resolve();
};

export const getRouteAfterFlow = () => (dispatch, getState) => {
  const {
    billing: { purchased },
  } = getState();
  return purchased ? SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS] : SUBSCRIPTION[SUBSCRIPTION_NAMES.MAIN];
};