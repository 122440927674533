import React from 'react';
import classNames from 'classnames';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { t } from '../localization';

import { ReactComponent as FaceIcon } from './images/face.svg';

import classes from './style.module.scss';

const tKey = 'welcome';

interface FaceReadingWelcomeProps {
  isSkipAvailable?: boolean;
  isUploadAvailable?: boolean;
  onCaptureClick: () => void;
  onUploadClick: () => void;
  onSkipClick: () => void;
}

export const FaceReadingWelcome: React.FC<FaceReadingWelcomeProps> = ({
  isSkipAvailable,
  isUploadAvailable,
  onCaptureClick,
  onUploadClick,
  onSkipClick,
}) => {
  const isFixedButton = isSkipAvailable || isUploadAvailable;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t(`${tKey}.title`)}</div>
      <FaceIcon
        className={classes.image}
      />
      <div className={classes.subtitle}>{t(`${tKey}.subtitle`)}</div>
      <div className={classes.footer}>
        <Button
          flare
          title={t(`${tKey}.button_title`)}
          onClick={onCaptureClick}
          iconRight
          className={classNames({ [classes.button_fixed]: !isFixedButton })}
        />
        {
          isSkipAvailable && (
            <LinkButton className={classNames(classes.link, classes.skip)} onClick={onSkipClick}>
              {t(`${tKey}.skip`)}
            </LinkButton>
          )
        }
        {
          isUploadAvailable && (
            <LinkButton className={classes.link} onClick={onUploadClick}>
              {t(`${tKey}.upload`)}
            </LinkButton>
          )
        }
      </div>
    </div>
  );
};
