export enum PaymentSystem {
  STRIPE = 'stripe',
  RECURLY = 'recurly',
  SOLIDGATE = 'solidgate',
  PADDLE = 'paddle',
  PAYPAL = 'paypal',
};

export enum ModeApplePay {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ENABLED_IF_WITH_CARD = 'enabled_if_with_card',
};

export enum ModeGooglePay {
  ENABLED_IF_PAYMENT_METHOD_PRESENT = 'enabled-if-payment-method-present',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
};

export type Period = 'P1W' | 'P2W' | 'P3W' | 'P1M' | 'P2M' | 'P3M' | 'P6M' | 'P1Y';

export type Currency = "USD" | "AUD" | "CAD" | "EUR";

export const Currencies: Array<Currency> = ["USD", "AUD", "CAD", "EUR"];

export interface Product {
  id: string,
  amount: string,
  amountDecimal?: string,
  currency: Currency,
  period: Period,
  trial_period_days: string,
  trial_price_amount: string,
}

export interface ProductInfo extends Product {
  trialPeriodDays: number,
  trialPeriodPrice: string,
  trialPeriodPriceValue: number,
  isTrial: boolean,
  isIntroductory: boolean,
  priceText: string,
  dayPrice: string,
  weekPrice: string,
  dayPriceAmount: string,
  weekPriceAmount: string,
  expireDate: string,
  expireAt?: number,
  introExpireDate: string,
  isOneTimePurchase: boolean,
}