import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ProgressDescriptionType } from '@web-solutions/face-reading/types';

import { selectFaceImgDims, selectFaceKeyPoints } from '../slice';

import { ReactComponent as Check } from './icons/check-min.svg';

import { Progress } from './components/progress';
import { FaceReadingPreview } from './components/preview';

import classes from './style.module.scss';

interface FaceReadingAnalyzingProps {
  analyzingDuration: number;
  onDone: () => void;
  progressDescriptionType?: ProgressDescriptionType;
}

export const FaceReadingAnalyzing: React.FC<FaceReadingAnalyzingProps> = ({
  analyzingDuration,
  onDone,
  progressDescriptionType
}) => {
  const finalProgressStep = 100;

  const pace = analyzingDuration / finalProgressStep;

  const dataFetched = !!useSelector(selectFaceKeyPoints);

  const { isFullScreen } = useSelector(selectFaceImgDims);

  const [progress, setProgress] = useState(0);

  const isFinished = progress === finalProgressStep;

  useEffect(() => {
    if (dataFetched) {
      const t = setInterval(() => {
        setProgress((p) => Math.min(p + 1, finalProgressStep));
      }, pace);
      return () => clearInterval(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);

  useEffect(() => {
    if (isFinished) {
      onDone();
    }
  }, [isFinished, onDone]);

  return (
    <div className={classNames(classes.wrapper, { [classes.full_mode]: isFullScreen, [classes.wrapper_finished]: isFinished })}>
      <FaceReadingPreview
        animated
        className={classNames(classes.container, { [classes.transformed]: progressDescriptionType === 'columns' && isFullScreen, [classes.container_finished]: isFinished })}
        progressDescriptionType={progressDescriptionType}
      />
      <div className={classNames(classes.check_mark, { [classes.check_mark_finished]: isFinished })}>
        <div className={classes.check_mark_third}>
          <div className={classes.check_mark_second}>
            <div className={classes.check_mark_first}>
              <Check />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(classes.footer, { [classes.footer_finished]: isFinished })}>
        <Progress
          progress={progress}
          progressDescriptionType={progressDescriptionType}
        />
      </div>
    </div>
  );
};
