export { ModeApplePay, ModeGooglePay } from '@web-solutions/react-billing/constants';

export enum ModePayPal {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DISABLED_IF_APPLE_PAY_WITH_CARD = 'disabled_if_apple_pay_with_card',
};

export enum InertialApplePay {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ENABLED_IF_WITH_CARD = 'enabled_if_with_card',
  ENABLED_WITH_DEFAULT = 'enabled_with_default',
};

export enum ErrorPopupType {
  CARD = 'card',
  BILLING = 'billing',
};
