import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaymentSystem } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Title, Button, LinkButton } from '@web-solutions/core/ui-elements';
import { TM } from '@web-solutions/module-localization';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';

import { t } from '../../../localization';

import { selectSubscription, useSliceDispatch, selectPaymentSystem, goAppLink, reactivate } from '../../slice';
import { ROUTES } from '../../constants/routes';
import { SubscriptionStatus } from '../../types';
import { useNavigateManage } from '../../hooks/use-navigate-next-screen';
import { SuccessModal } from '../../components/success-modal';

import { CurrentPlan } from './current-plan';
import { Features } from './features';

import classes from './style.module.scss';

const tKey = 'manage.main';
const analytics = 'manage_main';

export const ManageMainPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { mngSubMainPage } = useRemoteConfig();

  const [success, setSuccess] = useState(false);
  const [statusCode, setStatusCode] = useState('');
  const [error, setError] = useState('');

  const { navigateNextScreen } = useNavigateManage();

  const subscription = useSelector(selectSubscription);
  const paymentSystem = useSelector(selectPaymentSystem);
  const state = useSelector(state => state)
  console.log(state)

  useEffect(() => {
    Analytics.trackEvent(analytics, 'open');
  }, []);

  const handleReactivateClick = () => {
    Analytics.trackEvent('manage_reactivate', 'submit', { productId: subscription?.product });
    dispatch(reactivate())
      .unwrap()
      .then(() => {
        Analytics.trackEvent('manage_reactivate', 'success', { productId: subscription?.product });
        setSuccess(true);
      })
      .catch((e) => {
        Analytics.trackEvent('manage_reactivate', 'error', { productId: subscription?.product, code: e?.code, message: e?.message });
        setError(e?.message || t('manage.error.text'));
        setStatusCode(e?.code)
      });
  }

  const handleChangePlanClick = () => {
    navigate({
      pathname: ROUTES.PLANS,
      search: location.search,
    },
      {
        state: {
          fromMain: true,
        }
      });
  };

  const handleCancelSubscriptionClick = () => {
    Analytics.trackEvent(analytics, 'continue');
    navigateNextScreen();
  };

  const handleReturnToAppClick = () => {
    Analytics.trackEvent(analytics, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${analytics}_error_modal`, 'close');
    setError('');
    navigateNextScreen();
  }

  return (
    <div className={classes.wrap}>
      <Title level={'h1'}>{t(`${tKey}.title`)}</Title>

      {subscription
        ? (
          <>
            <CurrentPlan />
            <Features />
            {
              subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED
                ? (
                  <>
                    {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && (
                      <Button
                        onClick={handleReactivateClick}
                        className={classes.btn}
                        title={t(`${tKey}.reactivate_btn`)}
                      />
                    )}
                  </>
                )
                : (
                  <>
                    {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && (
                      <Button
                        onClick={handleChangePlanClick}
                        className={classes.btn}
                        title={<TM k={mngSubMainPage?.changePlanButtonText} defaultKey={t(`${tKey}.change_plan_btn`)} />}
                      />
                    )}
                    <LinkButton
                      className={classes.btn}
                      onClick={handleCancelSubscriptionClick}
                    >
                      {t(`${tKey}.cancel_sub_btn`)}
                    </LinkButton>
                  </>
                )
            }
          </>

        )
        : (
          <>
            <Features />
            <Title level={'h1'}>{t(`${tKey}.no_subscription`)}</Title>
            <Button
              onClick={handleReturnToAppClick}
              className={classes.btn}
              title={t(`${tKey}.return_to_app`)}
            />
          </>
        )}

      <SuccessModal
        category="manage_reactivate_success_modal"
        isOpen={success}
        tKey={`manage.plan.reactivated_modal`}
      />

      <ErrorPopup
        visible={!!error}
        onClose={handleCloseErrorClick}
        onSubmit={handleCloseErrorClick}
        buttonTitle={`OK`}
        statusCode={statusCode}
      />
    </div>
  );
};
