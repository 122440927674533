import { type LString } from '@web-solutions/module-localization';

export enum PriceAfterTrialType {
  WEEK = 'week',
  FULL = 'full',
};

export enum ButtonPlacementType {
  FIXED = 'fixed',
  TOP = 'top',
  PLANS = 'plans',
  PAYMENT = 'payment',
};

export enum ButtonBehaviorType {
  SCROLL = 'scroll',
  MODAL = 'modal',
  NEXT = 'next',
};

export enum CardFormLayout {
  STANDART = 'standart',
  COMPACT = 'compact',
}

export type ButtonConfig = {
  behavior?: ButtonBehaviorType,
  title: LString,
  uppercaseTitle: boolean,
  enabledFlareAnimation?: boolean,
}
