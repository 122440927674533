/* eslint-disable no-console */
import { createAction } from 'redux-actions';

import Billing from '@web-solutions/module-billing';

import Analytics from '@web-solutions/module-analytics';

import { setApiAuth } from 'core/utils/network';
import { stringifyUrlParams } from 'core/utils/url-sync';

import { auth as authRequest } from 'src/api/auth';

import { setPending } from '../app/actions';

import * as TYPES from './types';

export const setAuthorized = createAction(TYPES.SET_AUTHORIZED);
const setEmail = createAction(TYPES.SET_EMAIL);
export const resetEmail = createAction(TYPES.RESET_EMAIL);
export const setStressMeasure = createAction(TYPES.SET_STRESS_MEASURE);

const setAge = createAction(TYPES.SET_AGE);
const setGender = createAction(TYPES.SET_GENDER);
const setPregnant = createAction(TYPES.SET_PREGNANT);
const setDiabetes = createAction(TYPES.SET_DIABETES);

export const processAge = (age) => (dispatch, getState) => {
  Analytics.setUserProperty('age', age);
  dispatch(setAge(age));
}

export const processGender = (gender) => (dispatch, getState) => {
  Analytics.setUserProperty('gender', gender);
  dispatch(setGender(gender));
}

export const processPregnant = (pregnant) => (dispatch, getState) => {
  dispatch(setPregnant(pregnant));
}

export const processDiabetes = (diabetes) => (dispatch, getState) => {
  dispatch(setDiabetes(diabetes));
}

export const auth = () => async (dispatch, getState) => {
  return authRequest()
    .then((res) => {
      setApiAuth(`${res.token_type} ${res.access_token}`);
      Billing.setApiAuth(`${res.token_type} ${res.access_token}`);
      dispatch(setAuthorized(res));
    });
}

export const authAndSetUserData = (isSetPending) => async (dispatch) => {
  if (isSetPending) {
    dispatch(setPending(true));
  }

  try {
    await dispatch(auth());
  }
  catch (error) {
    if (error?.status !== 422) {
      throw error;
    }
    console.log('[ERROR AUTH]', error); 
  }
  finally {
    if (isSetPending) {
      dispatch(setPending(false));
    }
  }
};

export const processEmail = (email) => (dispatch, getState) => {
  Analytics.setUserProperty('email', email);
  Analytics.trackEvent('user', 'info', { email, });
  dispatch(setEmail(email));
  stringifyUrlParams({ email })
}
