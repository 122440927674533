import React from 'react';
import classNames from 'classnames';

//@ts-ignore
import { Title } from 'core/ui-elements';

import { t } from '../../../../localization';

import classes from './style.module.scss';

interface Props {
  progress: number,
  titleClassName?: string,
}

const tKey = 'analyzing';

export const DefaultProgressDescription: React.FC<Props> = ({ titleClassName, progress }) => {
  const stages = [
    { step: 0, title: t(`${tKey}.progress.analyzing`) },
    { step: 25, title: t(`${tKey}.progress.detecting`) },
    { step: 50, title: t(`${tKey}.progress.recognizing`) },
    { step: 75, title: t(`${tKey}.progress.interpreting`) },
    { step: 99, title: t(`${tKey}.progress.ready`) },
  ];
  return (
    <div className={classes.wrapper}>{
      stages.map(({ step, title }, index) => {
        const nextStep = stages[index + 1]?.step - 1 || 100;
        const isActiveElement = progress <= nextStep && progress >= step;
        const titleClass = classNames(titleClassName, classes.title, { [classes.active]: isActiveElement });

        return (
          <Title key={title} className={titleClass}>
            {title}...
          </Title>
        )
      })
    }
    </div>
  );
};
