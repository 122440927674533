import React from 'react';
import classNames from 'classnames';

import { getCurrencySymbols, getPreparedDayPrice, getPreparedWeekPrice } from 'core/../react-billing/utils/prices';

import { T, t, tm } from '@web-solutions/module-localization';

import { getProductTitle } from 'core/utils/products';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { parseCurrencyString } from 'core/utils/parse-currency';
import { createVATPrice } from 'core/utils/create-vat-price';
import { PriceAfterTrialType } from 'core/payment/constants';
import { useTimer } from 'core/payment/hooks/use-timer';
import TrialPrice from 'core/payment/base/product-info/components/total-price';
import { PaymentModalType } from 'core/constants/remote-config';
import { ProductDetails } from 'core/store/billing/selectors';

import EmailEditor from '../../components/edit-email-field';
import TrialsLeft from '../../components/trials-left';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

const priceTypes = {
  today: 'priceText',
  week: 'weekPrice',
  day: 'dayPrice',
}

const amountTypes = {
  today: 'amount',
  week: 'weekPriceAmount',
  day: 'dayPriceAmount',
}

const preparedPrices = {
  today: (_: any, val: number) => val,
  week: getPreparedWeekPrice,
  day: getPreparedDayPrice,
}

interface ProductInfoProps {
  product: ProductDetails,
  subtitle?: React.ReactNode,
}

export const ProductInfo: React.FC<ProductInfoProps> = ({
  product,
  subtitle,
}) => {
  const {
    title,
    textLineThrough,
    period,
    discount,
    isTrial,
    trialPeriodDays,
    trialPeriodPrice,
    isOneTimePurchase,
    currency,
  } = product;

  const {
    priceAfterTrialType,
    showPricesBlock,
    showAfterTrialPrice,
    displayVAT,
    totalPriceType,
    paymentModalType,
    showPriceDiscount,
  } = useRemoteConfig();

  const amountLineThrough = textLineThrough ? preparedPrices[totalPriceType](period, parseCurrencyString(tm(textLineThrough, ''))) : '';

  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);
  const textLineThroughByPeriod = `${currencySymbol}${+amountLineThrough}${currencySuffix}`

  //@ts-ignore
  const amount = product[amountTypes[totalPriceType]];
  //@ts-ignore
  const priceText = product[priceTypes[totalPriceType]];

  const { vat, price } = createVATPrice(amount, currency);
  const { price: priceLineThrough } = createVATPrice(amountLineThrough, currency);

  const titleText = getProductTitle(product);
  const priceString = displayVAT ? price : priceText;
  const todayPrice = !isTrial ? priceString : trialPeriodPrice;

  const timer = useTimer();
  const isShownTimerBlock = !!discount && timer?.shown;

  const totalPriceKey = isTrial ? 'today' : totalPriceType

  return <div className={classes.wrap}>
    {
      isOneTimePurchase
        ? (
          <div className={classes.row}>
            <p className={classes.aboutItemBlackText}>{tm(title, '')}</p>
            <p className={classes.aboutItemBlackText}>
              {!!textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '')}</span>}
              {priceText}
            </p>
          </div>
        )
        : (
          <>
            <div className={classes.block}>
              <div className={classes.row}>
                <p className={classes.productName}>
                  {t(`${tKey}.selected_titles`, { title: titleText })}
                </p>
              </div>
              {subtitle}
              {
                paymentModalType === PaymentModalType.BOTTOM_INJECTED && discount && (
                  <div className={classes.specialOffer}>
                    <div className={classes.title}>{t(`${tKey}.modal_discount.title`)}</div>
                    <div className={classes.text}>{t(`${tKey}.modal_discount.text`, { discount })}</div>
                  </div>
                )
              }
              {isShownTimerBlock && (
                <div className={classes.row}>
                  <p className={classes.discountTitle}>
                    {t(`${tKey}.${timer?.shown ? discount ? 'discount' : 'discount_zero' : 'discount_save'}`, { discount })}
                  </p>
                  {timer?.shown && (
                    <div className={classes.discount}>{timer?.text}</div>
                  )}
                </div>
              )}
            </div>

            <div className={classes.divider} />

            {showPricesBlock && (
              <>
                <div className={classes.block}>
                  {showPriceDiscount && (
                    <div className={classes.row}>
                      <p className={classes.aboutItemText}>
                        {tm(product.title, '')}
                      </p>
                      <div className={classes.leftColumn}>
                        <span className={classNames(classes.lineThrough, classes.disabled)}>
                          {tm(textLineThrough, '')}
                        </span>
                      </div>
                    </div>
                  )}
                  {showPriceDiscount && !!product.discount && !!product.discountPrice && (
                    <div className={classes.row}>
                      <p className={classes.aboutItemText}>
                        <b>{t(`${tKey}.personal_discount`, { discount: product.discount })}</b>
                      </p>
                      <div className={classes.leftColumn}>
                        <b>{tm(product.discountPrice, '')}</b>
                      </div>
                    </div>
                  )}
                  {isTrial && (
                    <>
                      <div className={classes.row}>
                        <p className={classes.aboutItemText}>
                          {t(`${tKey}.trial`, { days: trialPeriodDays })}
                        </p>
                        <div className={classes.leftColumn}>
                          <TrialsLeft type='text' />
                          <TrialPrice
                            textLineThrough={!showPriceDiscount ? tm(textLineThrough, '') : ''}
                            trialPeriodPrice={trialPeriodPrice}
                          />
                        </div>
                      </div>
                      {showAfterTrialPrice && (
                        <div className={classes.row}>
                          <p className={classes.aboutItemText}>{t(`${tKey}.after`)}</p>
                          <p className={classes.aboutItemText}>
                            {
                              priceAfterTrialType === PriceAfterTrialType.WEEK
                                ? t(`${tKey}.after_trial.${period}`, product)
                                : `${priceText}/${t(`${tKey}.price_texts.${period}`)}`
                            }
                          </p>
                        </div>
                      )}
                    </>
                  )}

                  <div className={classes.row}>
                    <p className={classes.aboutItemBlackText}>
                      <T
                        k={`${tKey}.total.${totalPriceKey || 'today'}`}
                        components={{ span: <span className={classes.smallText} /> }}
                      />
                    </p>
                    <p className={classes.aboutItemBlackText}>
                      {!isTrial && !!textLineThrough && !showPriceDiscount && <span className={classes.lineThrough}>{displayVAT ? priceLineThrough : textLineThroughByPeriod}</span>}
                      {todayPrice}
                    </p>
                  </div>
                  {!isTrial && displayVAT && <div className={classes.row}>
                    <p className={classes.vatText}>{t(`${tKey}.vat`)}</p>
                    <p className={classes.vatText}>
                      {vat}
                    </p>
                  </div>}
                </div>

                <div className={classes.divider} />
              </>
            )}

            <EmailEditor />
          </>
        )
    }
  </div>
};
