import Mutator from '@magnus/react-native-mutator';
//@ts-ignore
import Storage from '@magnus/react-native-mutator/src/storage';

import createRemoteConfigSnapshotNormalizer from './normalizer';

let _defaultValues: object;

const RemoteConfigModule = {
  fetch(token: string, defaultValues: object, { duration, storageKey }: { duration?: number, storageKey?: string } = {}): Promise<object> {
    Mutator.init({
      token,
      useBackendStorage: true,
      storageKey,
    });
    _defaultValues = defaultValues;
    return Mutator.fetch(duration)
      .catch(() =>
        Storage.getFetchCache()
          .catch(() => null)
          .then((c: any) => {
            if (c) {
              return Promise.resolve();
            }
            return import(`./${token}.json`).then(({ default: r }) => Storage.setFetchCache(r));
          }),
      )
      .then(() => this.activate());
  },

  activate(): Promise<object> {
    return Mutator.activate()
      .then(createRemoteConfigSnapshotNormalizer(_defaultValues))
      .then(({ remoteConfig }) => remoteConfig);
  },
};

export default RemoteConfigModule;
