import Lottie from 'lottie-react-web';

import rate from '../../animations/Rate.json';

interface RateProps {
  isFaceDetected: boolean;
}

export const RateAnimation: React.FC<RateProps> = ({ isFaceDetected }) => {
  return <Lottie
    width={'125px'}
    height={'75px'}
    speed={isFaceDetected ? 1 : 0}
    style={{
      margin: 0,
    }}
    options={{ animationData: rate }}
  />
}