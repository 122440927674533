export const SUBSCRIPTION_NAMES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  GUIDES: 'GUIDES',
} as const;

type SubscriptionNamesKeys = keyof typeof SUBSCRIPTION_NAMES;
type AfterPurchase = Exclude<typeof SUBSCRIPTION_NAMES[SubscriptionNamesKeys], "MAIN" | "SUCCESS">
export type AfterPurchaseRoutes = Array<AfterPurchase>;
export type AfterPurchaseStep = AfterPurchase;

export const QUIZ_NAMES = {
  QUIZ_WELCOME_FOCUS: 'QUIZ_WELCOME_FOCUS',
  QUIZ_WELCOME_PROCRASTINATION: 'QUIZ_WELCOME_PROCRASTINATION',
  QUIZ_WELCOME_ABHD: 'QUIZ_WELCOME_ABHD',
  QUIZ_WELCOME_GENDER: 'QUIZ_WELCOME_GENDER',
  QUIZ_WELCOME_LANDING: 'QUIZ_WELCOME_LANDING',
  QUIZ_EMAIL: 'QUIZ_EMAIL',
  QUIZ_MAGIC: 'QUIZ_MAGIC',
  QUIZ_CHECKMARK_MAGIC_LOADER: 'QUIZ_CHECKMARK_MAGIC_LOADER',

  QUIZ_GENDER: 'QUIZ_GENDER',
  QUIZ_AGE: 'QUIZ_AGE',
  QUIZ_PUTTING_OFF_TASKS: 'QUIZ_PUTTING_OFF_TASKS',
  QUIZ_MORE_INSPIRED: 'QUIZ_MORE_INSPIRED',
  QUIZ_WORRING_ABOUT: 'QUIZ_WORRING_ABOUT',
  QUIZ_LACK_OF_FOCUS: 'QUIZ_LACK_OF_FOCUS',
  QUIZ_DEEP_DOWN: 'QUIZ_DEEP_DOWN',
  QUIZ_TRANSITION_POTENTIAL: 'QUIZ_TRANSITION_POTENTIAL',
  QUIZ_CASES_IN_RELTIONSHIPS: 'QUIZ_CASES_IN_RELTIONSHIPS',
  QUIZ_RUINING_CAREER: 'QUIZ_RUINING_CAREER',
  QUIZ_EASY_DISTRACTED: 'QUIZ_EASY_DISTRACTED',
  QUIZ_ROUTINE: 'QUIZ_ROUTINE',
  QUIZ_CONSEQUENCES: 'QUIZ_CONSEQUENCES',
  QUIZ_OVERBURDENED: 'QUIZ_OVERBURDENED',
  QUIZ_PROCRASTINATION: 'QUIZ_PROCRASTINATION',
  QUIZ_TRANSITION_VICIOUS_CYCLE: 'QUIZ_TRANSITION_VICIOUS_CYCLE',
  QUIZ_SELF_IMPROVEMENT: 'QUIZ_SELF_IMPROVEMENT',
  QUIZ_DAILY_ROUTINE: 'QUIZ_DAILY_ROUTINE',
  QUIZ_TRANSITION_NEW_HABITS: 'QUIZ_TRANSITION_NEW_HABITS',
  QUIZ_MEETING_FAIRY: 'QUIZ_MEETING_FAIRY',
  QUIZ_QUIT_HABITS: 'QUIZ_QUIT_HABITS',
  QUIZ_WHAT_STOPS: 'QUIZ_WHAT_STOPS',
  QUIZ_TRANSITION_NOT_ALONE: 'QUIZ_TRANSITION_NOT_ALONE',
  QUIZ_IMAGINE: 'QUIZ_IMAGINE',
  QUIZ_INVEST_TODAY: 'QUIZ_INVEST_TODAY',
  QUIZ_AFTER_MONTH: 'QUIZ_AFTER_MONTH',
  QUIZ_TRANSITION_START_TODAY: 'QUIZ_TRANSITION_START_TODAY',
  QUIZ_TRANSITION_REVIEW: 'QUIZ_TRANSITION_REVIEW',

  QUIZ_TRANSITION_TIME: 'QUIZ_TRANSITION_TIME',
  QUIZ_TRANSITION_WILLPOWER: 'QUIZ_TRANSITION_WILLPOWER',
  QUIZ_TRANSITION_STEPS: 'QUIZ_TRANSITION_STEPS',
  QUIZ_TRANSITION_ALMOST_THERE: 'QUIZ_TRANSITION_ALMOST_THERE',
  QUIZ_TRANSITION_GOT_THIS: 'QUIZ_TRANSITION_GOT_THIS',
  QUIZ_TRANSITION_PROUD: 'QUIZ_TRANSITION_PROUD',

  QUIZ_TIME_IN_PHONE: 'QUIZ_TIME_IN_PHONE',
  QUIZ_STAY_PRODUCTIVE: 'QUIZ_STAY_PRODUCTIVE',
  QUIZ_CANT_STOP_CHECKING: 'QUIZ_CANT_STOP_CHECKING',
  QUIZ_TRACK_OF_TIME: 'QUIZ_TRACK_OF_TIME',
  QUIZ_CONTROL_PHONE_HABITS: 'QUIZ_CONTROL_PHONE_HABITS',
  QUIZ_HELP_FOCUS: 'QUIZ_HELP_FOCUS',
  QUIZ_PHONE_ADDICTION: 'QUIZ_PHONE_ADDICTION',
  QUIZ_FOCUS_AFTER_MONTH: 'QUIZ_FOCUS_AFTER_MONTH',
} as const;

export type HabioFlowStep = typeof QUIZ_NAMES[keyof typeof QUIZ_NAMES];
